import buttons from './buttons'

export default {
  // ? ==========================
  // ? =====  Multilocation  ====
  // ? ==========================

  multiLocation: {},

  // ? ===================
  // ? =====  Layout  ====
  // ? ===================

  navigation: {
    color: 'white',

    '.container': {
      padding: ['1rem', '1rem', '1rem', '1rem']
    },
    '.containerScrolled': {
      padding: ['1rem', '1rem', '1rem', '1rem']
    },
    '.navItem': {
      a: {
        letterSpacing: '-1px',
        fontWeight: '500',
        fontSize: ['1.25rem', '1.1rem', '1.25rem', '1.5rem']
      }
    },
    '.smallNavMenu': {
      mr: '2rem'
    },

    '.logo': {
      img: {
        maxHeight: ['', '', '185px']
      }
    },

    '.hamburger, .hamburgerOpen': {
      backgroundColor: 'primary',
      opacity: '0.81',
      padding: '1rem',
      height: '60px',
      width: '60px',
      border: 'solid 2px white',
      borderRadius: '500px',
      div: {
        backgroundColor: 'white'
      }
    },
    '.hamburgerOpen': {
      position: 'relative',
      div: {
        position: 'absolute',
        top: '50%',
        left: '50%'
      },
      'div:nth-of-type(1)': {
        transform: 'translate(-50%,-50%) rotate(135deg)'
      },
      'div:nth-of-type(2)': {
        transform: 'translate(-50%,-50%) rotate(-135deg)'
      }
    },
    '.navMenuOpen': {
      padding: ['2rem', '', '3rem']
    }
  },

  footer: {
    position: 'relative',
    backgroundColor: 'primary',
    color: 'white',
    '.logo': {
      marginBottom: '1rem',
      position: 'relative',
      top: ['', '', '', '-1rem']
    },

    '.multiButtonContainer': {
      flexDirection: ['row', '', '', 'column'],
      a: {
        color: 'white'
      }
    },
    '.contactDetails-container .text': {
      fontSize: ['1rem', '1.25rem']
    },
    '.poweredByText': {
      svg: {
        fill: 'white'
      }
    }
  },

  ctaWidget: {
    // display: 'none!important'
  },
  // ? =============================
  // ? ====  Reservations page  ====
  // ? =============================

  reservations: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    padding: '20vh 1rem',

    '.textContent': {
      marginBottom: '2rem',
      textAlign: 'center'
    },
    '.title': {
      fontSize: ['2rem', '', '2.5rem'],
      fontWeight: '400'
    }
  },
  // ? ======================
  // ? ====  reuseables  ====
  // ? ======================

  title: {
    fontFamily: 'heading',
    color: 'primary',
    textTransform: 'uppercase',
    mb: '0rem',
    fontSize: ['1.75rem', '2rem', '2.5rem', '3rem', '3.5rem'],
    order: '1'
  },
  subtitle: {
    fontFamily: 'subheading',
    color: 'secondary',
    border: 'none',
    order: '2',
    fontWeight: '300',
    mb: '2rem'
  },
  text: {
    fontFamily: 'body',
    color: '#76a2a9',
    order: '3'
  },

  ctaLink: {
    order: '4'
  },

  sideBySide1: {
    my: '10vh',
    '.lazyload-wrapper': {
      minHeight: ['', '', '', '70vh']
    },
    '.content': {
      // order: ['', '', '', '2'],
      padding: ['1rem', '', '', '1rem 6rem 1rem 6rem']
    },
    '.title': {
      variant: 'customVariants.title'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle'
    },
    '.text': {
      variant: 'customVariants.text',
      paddingLeft: '1rem',
      borderLeft: '3px solid',
      borderColor: 'primary',
      h3: {
        color: 'brandPink'
      }
    },
    a: {
      variant: 'customVariants.ctaLink'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    },
    '.image': {
      maxHeight: '600px'
    }
  },

  // ? ====================
  // ? ====  Homepage  ====
  // ? ====================

  homepageHero: {
    // '.innerContainer': {
    backgroundPosition: 'bottom center',
    // },
    position: 'relative',
    '::before': {
      content: "''",
      width: '100%',
      height: '100vh',
      background: 'linear-gradient(9deg, rgba(0,0,0,1) 0%, rgba(26,45,83,1) 13%, rgba(255,255,255,0) 100%)',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)'
    },
    '.muteToggle': {
      right: '1rem',
      top: '100px'
    },
    '.section': {
      // px: '2rem',
      border: 'none',
      width: '100%',
      margin: '1rem 2rem',
      '.title': {
        fontSize: ['2.5rem', '3.5rem', '4rem', '4.5rem', '5.5rem'],
        textTransform: 'uppercase',
        fontWeight: '400',
        border: 'none',
        padding: '0rem',
        textShadow: '2px 2px 30px black'
      },
      '.subtitle': {
        fontSize: ['1.5rem', '2rem', '2.5rem', '3rem', '3.5rem'],
        textTransform: 'uppercase',
        fontWeight: '400',
        border: 'none',
        padding: '0rem',
        color: '#2482e0'
      },
      '.text': {
        '*': {
          color: 'white',
          textShadow: ' 2px 2px 8px black'
        }
      },
      a: {
        position: ['', '', '', 'absolute'],
        right: '0rem',
        bottom: '2rem'
      },
      '.ctaButton': {
        variant: 'buttons.primary',
        maxWidth: '400px'
      }
    }
  },

  homepageHeroShout: {},

  homepageShout: {
    padding: ['1rem', '1rem', '3rem 2rem 12rem'],
    // marginBottom: '7rem',
    position: 'relative',
    // overflow: 'hidden',
    // zIndex: ['', '', '-1'],
    flexDirection: ['column', 'column', 'row'],
    '.imageContainer': {
      maxHeight: ['', '', '80vh'],
      position: ['', '', 'relative'],
      width: ['100%', '100%', '50%'],
      top: '1rem',
      left: ['0rem', '', '2rem', '6rem'],
      order: ['2', '', '1'],
      border: ['', '', 'solid 6px'],
      borderColor: ['', '', 'secondaryLight'],
      img: { objectFit: 'contain' }
    },

    '.content': {
      position: ['', '', 'relative'],
      width: ['100%', '100%', '50%'],
      top: '6rem',
      left: '0rem',
      backgroundColor: '#e0f4fd',
      border: 'solid 7px',
      boxShadow: ['', '', '-20px -19px 0px 0px white'],
      borderColor: 'secondary',
      padding: ['', '', '', '2rem 5rem'],
      alignItems: 'flex-start',
      textAlign: 'left',
      order: ['1', '', '2']
    },
    '.title': {
      fontFamily: 'subheading',
      margin: '1rem 0rem',
      textAlign: 'left',
      fontSize: ['1.5rem', '', '2.5rem'],
      fontWeight: 'bold',
      color: 'secondary',
      position: ['', '', '', 'absolute'],
      left: ['', '', '', '-105%'],
      top: ['', '', '', '0%'],
      writingMode: ['', '', '', 'vertical-lr'],
      transform: ['', '', '', 'rotate(180deg)'],
      transformOrigin: ['', '', '', 'center'],
      width: ['', '', '', 'fit-content'],
      height: ['', '', '', 'fit-content']
    },
    '.date': {
      margin: '1rem 0rem',
      textAlign: 'left',
      display: 'none!important'
    },
    '.text': {
      fontFamily: 'heading',
      color: 'brandBlue',
      fontWeight: 'bold',
      margin: '1rem 0rem',
      textAlign: 'left',
      textTransform: 'uppercase',
      fontSize: ['1.25rem', '1.5rem', '1.75rem', '2rem', '2.5rem']
    },
    '.shoutCTASBtns': {
      width: '100%'
    },

    '.shoutCTA': {
      variant: 'buttons.primary',
      padding: '1rem'
    }
  },

  homepageAboutSlider: {
    backgroundColor: 'background',
    margin: '0rem',
    padding: ['', '', '4rem 0rem'],
    backgroundImage: [
      '',
      '',
      'url(https://res.cloudinary.com/gonation/image/upload/v1684501731/sites/tiki-beach/tiki_icon.png)'
    ],
    backgroundRepeat: 'no-repeat',
    backgroundSize: ['30%', '', '25%'],
    backgroundPosition: ['top left', '', 'left center'],
    '.hero_content_container': {
      margin: ['', '', '0rem 0rem 6rem auto', '', '0rem 2rem 6rem auto'],
      position: 'static',
      padding: ['2rem 1rem', '', '0rem 1rem', '0rem 1rem 0rem 3rem', '0rem 1rem 0rem 5rem'],
      borderLeft: ['', '', '3px solid'],
      borderColor: ['', '', 'primaryLight']
    },
    '.title': {
      variant: 'customVariants.title',
      fontSize: ['2rem', '', '2.25rem', '2.5rem', '2.5rem', '3rem'],
      fontSize: ['2rem', '2.25rem', '3rem', '3.5rem', '4rem'],
      paddingLeft: ['', '', '1rem', '2rem', '3rem'],
      display: 'flex',
      border: ['', '', 'none'],
      alignItems: 'center',
      left: '0rem',
      top: '4rem',
      lineHeight: '1.5',
      marginBottom: ['1.5rem', '', '0rem'],
      maxWidth: 'unset',
      width: ['100%', '', '40%']
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      fontSize: ['1.75rem', '', '2rem'],
      whiteSpace: ['', '', '', ''],
      fontWeight: 'bold',
      mb: '1.5rem'
    },
    '.text': {
      variant: 'customVariants.text',
      // textTransform: 'uppercase',
      fontSize: ['1rem', '', ''],
      color: '#76a2a9',
      lineHeight: '2'
    },
    a: {
      order: '4',
      '.ctaButton': {
        variant: 'buttons.primary'
      }
    },

    '.slick-slider': {
      borderLeft: ['', '', '10px solid'],
      borderColor: ['', '', 'secondaryLight'],
      width: ['100%', '', '', '80%'],
      left: ['', '', '', '20%'],
      top: ['', '', '2rem'],
      '.slick-slide': {
        img: {
          filter: 'brightness(1)'
        }
      },
      '.slick-prev': {
        backgroundColor: 'secondary'
      },
      '.slick-next': {
        backgroundColor: '#bcd1e7',
        color: 'brandBlue'
      }
    }
  },

  homepageAbout: {
    variant: 'customVariants.sideBySide1'
  },

  homepageMenu: {
    variant: 'customVariants.sideBySide1'
  },

  homepageEvents: {
    variant: 'customVariants.sideBySide1'
  },

  homepageGallery: {
    variant: 'customVariants.sideBySide1'
  },

  homepageContact: {
    variant: 'customVariants.sideBySide1',
    '.content': {
      width: ['', '', '50%']
    },
    '.image': {
      maxHeight: 'unset'
    },
    '.imageContainer': { width: ['', '', '50%'] }
  },

  // ? ========================
  // ? =====  About page  =====
  // ? ========================

  aboutSection: {
    flexDirection: ['column', 'column', 'column', 'row'],
    padding: ['1rem', , '2rem'],
    '.lazyload-wrapper': {
      width: ['100%', '100%', '100%', '30%']
    },
    '.title': {
      color: 'grey',
      fontWeight: '400'
    },
    '.subtitle': {
      textTransform: 'uppercase',
      fontWeight: 'bold',
      letterSpacing: '2px',
      fontSize: '2rem',
      marginBottom: '2rem'
    },
    '.content': {
      order: ['', '', '', '1'],
      border: 'none',
      borderWidth: '5px',
      padding: ['1rem 0rem', , 8],
      width: ['100%', '100%', '100%', '68%'],
      margin: ['', '', '', 'auto 0 0']
    },

    // '.lazyload-wrapper::after': {
    //   content: "'Photo (c) Felix Angel'",
    // },
    '.image': {
      order: ['', '', '2'],
      width: ['100%', '100%', '100%', '100%'],
      maxHeight: '100vh'
    }
  },

  acknowledgementsTitle: {
    padding: ['1.75rem', '2rem'],
    paddingLeft: ['', '', '', '31%'],
    '.title': {
      textAlign: 'left',
      fontSize: '2rem'
    }
  },
  acknowledgementsSection1: {
    padding: '0rem 2rem 1rem',
    alignItems: 'flex-start',
    paddingLeft: ['', '', '', '31%'],
    '.section': {
      alignItems: 'flex-start',
      margin: '0rem',
      maxWidth: 'unset'
    },

    '.title': {
      fontSize: '1.5rem',
      opacity: '0.8',
      textAlign: 'left'
    },
    '.text': {
      textAlign: 'left',
      maxWidth: 'unset'
    }
  },
  acknowledgementsSection2: {
    padding: '0rem 2rem 1rem',
    alignItems: 'flex-start',
    paddingLeft: ['', '', '', '31%'],
    '.section': {
      alignItems: 'flex-start',
      margin: '0rem',
      maxWidth: 'unset'
    },

    '.title': {
      fontSize: '1.5rem',
      opacity: '0.8',
      textAlign: 'left'
    },
    '.text': {
      textAlign: 'left',
      maxWidth: 'unset'
    }
  },

  // ? ========================
  // ? ====  Menu page  ====
  // ? ========================
  menuPageHero: {
    height: '80vh',
    width: '100%',
    '.videoContainer': {
      width: '100%',
      video: {
        margin: '0 auto'
      }
    }
  },
  menu: {
    padding: ['0rem', '0rem', '0rem', '0rem'],

    '.innerMenuContainer': {
      padding: '0rem'
      // '> div': {
      //   display: 'flex',
      //   flexDirection: ['', '', '', 'row'],
      // },
      // backgroundImage:
      //   "url('https://res.cloudinary.com/gonation/image/upload/v1684501731/sites/tiki-beach/tiki_icon.png')",
      // backgroundRepeat: 'no-repeat',
      // backgroundSize: '50%',
      // backgroundPosition: 'top left'
    },

    '.allInContainerWrapper': {
      maxWidth: '1200px',
      margin: '0 auto',
      padding: ['1rem', '0.5rem', '']
    },
    '.tabsRow': {
      // flexDirection: ['', '', '', 'column'],
      padding: '0rem',
      borderTop: 'solid 1px',
      borderColor: 'black',
      marginBottom: ['1rem', '2rem', '3rem']
    },
    '#menuTab': {
      margin: '0rem',
      border: 'none',
      padding: ['0.5rem 1rem', '', '0.75rem 1.25rem'],
      borderBottom: 'solid 1px',
      borderColor: 'black',
      flexGrow: '1',
      maxWidth: 'unset',
      minWidth: ['125px', '', '', '200px'],
      width: 'auto',
      display: 'flex',
      justifyContent: 'center',
      textAlign: 'center',
      fontFamily: 'heading',
      textTransform: 'uppercase',
      color: 'brandBlue'
    },
    '.menuSectionTitle': {
      color: 'white',
      fontSize: ['1.5rem', '1.75rem', '2rem', '2.5rem'],
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      textAlign: 'left',
      margin: '1.5rem 1.5rem 2.5rem',
      padding: '1rem',
      backgroundColor: 'primary',
      border: 'solid 4px #bbbbbb',
      width: 'fit-content'
    },
    '.menuSectionDescription': {
      color: 'primary'
    },
    '.menuItemName': {
      marginBottom: '0rem',
      textTransform: 'uppercase',
      color: 'secondary',
      fontFamily: 'subheading'
    },
    '.menuItemDescription': {
      opacity: '0.6',
      fontWeight: '600',
      textTransform: 'uppercase',
      color: '#256b86'
    },
    '.dropdownContainer': {
      display: 'none'
    },
    '.menuItemPrice': {
      textTransform: 'uppercase',
      fontFamily: 'subheading',
      fontSize: ['1.1rem', '1.25rem', '1.5rem']
    },

    '.itemVariantsContainer': {
      backgroundColor: 'secondary',
      width: 'fit-content',
      p: '0.5rem',
      borderRadius: '10px',
      my: '0.75rem'
    },

    '.variantContainer': {
      mb: '0rem'
    },
    '.menuItemPriceLabel, .menuItemPriceVariants': {
      textTransform: 'uppercase',
      color: 'white',
      fontFamily: 'body',
      fontSize: '0.8rem',
      fontWeight: 'bold'
    }
  },

  // ? ========================
  // ? ====  Gallery page  ====
  // ? ========================

  gallery: {
    mt: '200px'
  },

  // ? ========================
  // ? ====  Events page  ====
  // ? ========================

  events: {
    '.events-container': {
      maxWidth: 'unset'
    },

    '.special-events-container > .title, .recurring-events-container > .title': {
      display: 'none'
    },
    '.eventItemContent': {
      padding: ['1rem', '', '', '3rem', '4rem']
    },
    '.eventItemImage ': {
      maxHeight: 'unset'
    },

    '.eventItemTitle': {
      marginBottom: '1rem',
      color: 'primary',
      fontSize: ['', '', '', '2rem', '2.5rem']
    },
    '.eventItemDescription': {
      marginBottom: '1rem',
      color: 'brandBlue'
    },
    '.eventTime': {
      color: 'brandBlue'
    },
    '.eventDate': {
      color: 'brandPink'
    },
    '.eventMonth': {
      color: 'secondary'
    },

    '.eventCTABtns': {
      a: {
        variant: 'buttons.primary',
        fontFamily: 'heading'
      }
    },

    '.eventItemBtn ': {
      display: 'none'
    },

    // "event orders"

    '.eventItemContainer': {
      order: '2'
    },
    '.evt-UWf52nT8S1iJx3mCDYq5Sg': {
      order: '1'
    }
  },

  // ? ========================
  // ? ====  Contact page  ====
  // ? ========================

  contactForm: {
    order: '2',
    backgroundColor: 'secondary',
    h2: {
      textTransform: 'uppercase',
      color: 'primary',
      marginBottom: '1rem'
    },
    // '.address, .phone': {
    //   display: 'none',
    // },
    backgroundPosition: 'center center',
    color: 'white',
    padding: ['3rem 1rem', '', '9rem 1rem'],
    '.text': {
      color: 'white'
    },
    '.title': {
      fontSize: ['1.5rem', '1.5rem', '1.7rem', '1.75rem'],
      lineHeight: '1.5',
      color: 'secondaryLight',
      fontWeight: '800'
    },
    '.inputField, .textField ': {
      fontWeight: '300',
      border: 'none',
      borderBottom: '1px solid',
      borderColor: 'primaryLight',
      borderWidth: '2px',
      borderRadius: '0px',
      padding: '1rem 0.5rem',
      color: 'white',
      backgroundColor: 'rgba(0,0,0,0.25)',
      borderRadius: '5px',
      padding: '1.5rem 1rem',
      '::placeholder': {
        color: 'white'
      }
    }
  },

  locationMap: {
    '.title': {
      fontSize: ['1.25rem', '1.5rem', '1.7rem', '1.75rem'],
      color: 'secondaryLight',
      fontWeight: '800'
    },

    '.content_container': {
      color: 'brandBlue'
    }
  },

  // ? ========================
  // ? =====  Beach Club  =====
  // ? ========================

  brochure: {
    marginTop: '2rem',
    '.content': {
      alignItems: 'flex-start',
      width: ['100%', '', '75%']
    },
    '.title': {
      fontSize: ['1.5rem', '1.5rem', '2rem', '3rem'],
      color: 'brandBlue',
      textAlign: 'left'
    },
    '.subtitle': {
      textAlign: 'left',
      fontFamily: 'body',
      color: 'brandOrange'
    },

    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  beachClubMenu: {
    variant: 'customVariants.menu',
    '.membership-packages': {
      '.menuItemsContainer': {
        display: 'flex',

        '.menuItemContainer,.menuItemContainerImgActive': {
          backgroundColor: '#6eb2bdd6',
          width: ['', '', 'calc(33.3% - (0.5rem * 2))'],
          '.menuItemName': {
            marginBottom: '1rem'
          }
        }
      }
    },
    '.membership-benefits': {
      '.menuItemContainerImgActive, .menuItemContainer': {
        width: '100%',
        '.menuItemInnerContainer': {
          flexDirection: ['column', '', 'row']
        },
        '.menuItemImageContainer': {
          maxHeight: '250px',
          maxWidth: '350px'
        },
        img: {
          objectFit: 'contain'
        }
      }
    }
  },

  clubEvents: {
    variant: 'customVariants.menu',
    '.menuItemName': {
      fontFamily: 'heading',
      textTransform: 'uppercase',
      color: 'brandBlue'
    }
  },

  // ? ========================
  // ? ====  Specials menu  ===
  // ? ========================

  specialsHero: {
    '.title': {
      fontSize: ['1.5rem', '2rem', '2.5rem', '3.5rem'],
      fontWeight: 'normal'
    },
    '.hero_content_container': {
      width: '100%',
      margin: '0rem',
      border: 'none'
    }
  },
  // ? ========================
  // ? ====  Beer Garden   ===
  // ? ========================

  beerGardenMenuIntro: {
    variant: 'customVariants.homepageMenu',
    minHeight: 'unset',
    my: '0vh',
    'div.lazyload-wrapper': {
      width: ['', '', '30%']
    },
    'div.content': {
      width: ['', '', '70%']
    },
    'img.image': {
      objectFit: 'contain'
    },
    'div.text': {
      border: 'none',
      p: '0rem',
      my: '2rem'
    }
  },

  // ? ========================
  // ? ====  Cabanas Fall  ====
  // ? ========================

  cabanas: {
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  fallBlock: {
    background: 'unset',
    '.title': {
      marginBottom: '2rem',
      color: 'primary'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  cabanaAlbum: {
    '#': {
      display: 'none'
    },
    '.albumsContainer, .albumName': {
      display: 'none'
    }
  },

  // ? ========================
  // ? ====  Parking ====
  // ? ========================

  parkingOffer: {
    variant: 'customVariants.sideBySide1',
    marginTop: '0rem',
    '::before': {
      content: "''",
      backgroundColor: 'primary',
      width: '100%',
      height: '220px',
      marginBottom: '3rem'
    },
    flexWrap: 'wrap'
  }
}
