export default {
  text: '#202020',
  primary: '#203d78',
  primaryLight: '#9eb4e1',
  secondary: '#4584f5',
  secondaryLight: '#2adaba',
  brandOrange: '#f58345',
  brandPink: '#ce2d4f',
  brandBlue: '#023871',
  background: '#FFF',
  backgroundSecondary: '#4a4a4a',
  light: '#FFF',
  dark: '#313131'
}
